import {Dialog, DialogContent, DialogFooter, DialogHeader} from './ui/dialog'
import {Button} from './ui/button'
import {ErrorMessage} from './ErrorMessage'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: () => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
  destructive?: boolean
  loading?: boolean
  error?: any
  children: React.ReactNode
}

export function ConfirmDialog({
  open,
  onOpenChange,
  onConfirm,
  onCancel,
  title,
  confirmText,
  cancelText,
  children,
  loading,
  destructive,
  error
}: Props) {
  function handleOpenChange(newOpen: boolean) {
    if (!newOpen && onCancel) {
      onCancel()
    }
    onOpenChange(newOpen)
  }
  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogHeader title={title || 'Confirm'} />
        {children}
        <DialogFooter>
          <Button
            onClick={onConfirm}
            variant={destructive ? 'destructive' : 'default'}
            disabled={loading}
          >
            {confirmText || 'Confirm'}
          </Button>
          <Button
            onClick={() => handleOpenChange(false)}
            variant="secondary"
            disabled={loading}
          >
            {cancelText || 'Cancel'}
          </Button>
        </DialogFooter>
        <ErrorMessage error={error} />
      </DialogContent>
    </Dialog>
  )
}

interface Props {
  children: React.ReactNode
}

export const SubmenuPageContainer = ({children}: Props) => {
  return (
    <div className="p-4 pb-16 lg:pt-4 lg:px-6">
      <div className="w-full max-w-3xl">{children}</div>
    </div>
  )
}

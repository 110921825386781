import '@/index.css'
import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import {ApolloProvider} from '@apollo/client'
import {client} from '@/utils/apollo-client'
import {RouterProvider} from 'react-router-dom'
import {router} from '@/router'
import {AuthProvider} from '@/components/AuthProvider'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ApolloProvider>
  </StrictMode>
)

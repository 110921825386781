import {LoadingSpinner} from '@/components/LoadingSpinner'
import {useAuth} from '@/hooks/useAuth'
import {Navigate, Outlet} from 'react-router-dom'

export default function Unauthenticated() {
  const {viewerLoaded, viewer} = useAuth()

  if (!viewerLoaded) {
    return (
      <div className="p-6">
        <LoadingSpinner />
      </div>
    )
  }

  if (viewer) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

import {DialogFooter} from './ui/dialog'
import {Button} from './ui/button'

interface Props {
  submitText?: string
  cancelText?: string
  disabled: boolean
  onSubmit?: () => void
  onClose: () => void
}

export function DialogButtons({
  submitText,
  cancelText,
  disabled,
  onSubmit,
  onClose
}: Props) {
  return (
    <DialogFooter>
      <Button
        type={onSubmit ? 'button' : 'submit'}
        disabled={disabled}
        onClick={onSubmit}
      >
        {submitText || 'Submit'}
      </Button>
      <Button onClick={onClose} variant="secondary" disabled={disabled}>
        {cancelText || 'Cancel'}
      </Button>
    </DialogFooter>
  )
}

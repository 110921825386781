import {Header} from '@/components/Header'
import {PageContainer} from '@/components/PageContainer'

export default function Home() {
  return (
    <PageContainer>
      <Header>Home</Header>
    </PageContainer>
  )
}

export function isScoopIOS(): boolean {
  return window.webkit?.messageHandlers.scoop != null
}

export function isIOSSafari(): boolean {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit && !ua.match(/CriOS/i)
}

interface EnvelopeToScoopIOS {
  requestId: string
  message: MessageToToScoopIOS
}

type MessageToToScoopIOS =
  | {
      kind: 'ShowAlert'
      message: string
    }
  | {
      kind: 'GetPushNotificationStatus'
    }
  | {
      kind: 'EnablePushNotifications'
    }

let sendListenerRegistered = false
const resolvers: Record<
  string,
  {
    resolve: (message: any) => void
    reject: (error: Error) => void
  }
> = {}

export function sendMessageToScoopIOS<TKind extends string>(
  message: MessageToToScoopIOS & {kind: TKind},
  {noReply}: {noReply?: boolean} = {}
): Promise<MessageFromScoopIOS & {kind: `${TKind}Reply`}> {
  if (!window.webkit?.messageHandlers.scoop) {
    throw new Error('Cannot run when not running in Scoop for iOS')
  }
  if (!sendListenerRegistered) {
    sendListenerRegistered = true
    onMessageFromScoopIOS(envelope => {
      if (!envelope.replyForRequestId) {
        return
      }
      const resolver = resolvers[envelope.replyForRequestId]
      if (!resolver) {
        return
      }
      resolver.resolve(envelope.message)
    })
  }
  return new Promise((resolve, reject) => {
    const requestId = crypto.randomUUID()
    if (!noReply) {
      resolvers[requestId] = {resolve, reject}
    }
    const envelope: EnvelopeToScoopIOS = {
      requestId,
      message
    }
    console.log('[sendMessageToScoopIOS]', envelope)
    window.webkit!.messageHandlers.scoop.postMessage(JSON.stringify(envelope))
  })
}

// export function sendMessageToScoopIOS(message: MessageToToScoopIOS) {
//   if (!window.webkit?.messageHandlers.scoop) {
//     return
//   }
//   window.webkit.messageHandlers.scoop.postMessage(JSON.stringify(message))
// }

// export function useScoopIOSCommunication() {
//   const unsubscribes = useRef<(() => void)[]>([])
//   useEffect(() => {
//     const unsubscribeFns = unsubscribes.current
//     return () => {
//       unsubscribeFns.forEach(unsubscribe => unsubscribe())
//     }
//   }, [])

//   function sendRequest(message: MessageToToScoopIOS) {
//     const requestId = crypto.randomUUID()
//     message.requestId = requestId
//     sendMessageToScoopIOS(message)
//     const unsubscribe = onMessageFromScoopIOS(message => {
//       console.log('onMessageFromScoopIOS', message)
//       switch (message.kind) {
//         case 'pushNotificationStatus':
//           setStatus(message.status)
//           break
//       }
//     })
//     unsubscribes.current.push(unsubscribe)
//   }

//   return {sendMessage}
// }

interface EnvelopeFromScoopIOS {
  replyForRequestId: string | null
  message: MessageFromScoopIOS
}

type MessageFromScoopIOS =
  | {
      kind: 'ShowToast'
      message: string
    }
  | {
      kind: 'GetPushNotificationStatusReply'
      status: 'enabled' | 'disabled' | 'denied'

      token: string | null
    }
  | {
      kind: 'EnablePushNotificationsReply'
      denied: boolean
      name: string | null
      token: string | null
      error: string | null
    }

export function onMessageFromScoopIOS(
  callback: (message: EnvelopeFromScoopIOS) => void
) {
  const onMessage = (event: MessageEvent) => {
    if (event.data.scoopIOSEnvelope) {
      const envelope = event.data.scoopIOSEnvelope as EnvelopeFromScoopIOS
      console.log('[onMessageFromScoopIOS]', envelope)
      callback(envelope)
    }
  }
  window.addEventListener('message', onMessage)
  return () => {
    window.removeEventListener('message', onMessage)
  }
}

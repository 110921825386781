import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation
} from '@apollo/client'
import {Socket as PhoenixSocket} from 'phoenix'
import * as AbsintheSocket from '@absinthe/socket'
import {createAbsintheSocketLink} from '@absinthe/socket-apollo-link'

const backendOrigin = import.meta.env.PROD
  ? location.origin
  : 'http://localhost:5051'

const socketUrl = new URL(backendOrigin)
socketUrl.protocol = socketUrl.protocol === 'http:' ? 'ws:' : 'wss:'
const socketOrigin = socketUrl.origin

const httpLink = new HttpLink({
  uri: op => {
    return backendOrigin + '/gql?op=' + op.operationName
  },
  credentials: 'include'
})

class SocketSubscriptionSplitLink extends ApolloLink {
  httpLink: ApolloLink
  absintheSocketLink: ApolloLink | null = null

  constructor(httpLink: ApolloLink) {
    super()
    this.httpLink = httpLink
  }

  operationIsSubscription(operation: Operation): boolean {
    return operation.query.definitions.some(
      definition =>
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    )
  }

  request(operation: Operation) {
    if (this.operationIsSubscription(operation)) {
      if (!this.absintheSocketLink) {
        throw new Error(
          `Can't use GraphQL subscriptions until setApolloSocketToken has been called`
        )
      }
      return this.absintheSocketLink.request(operation)
    } else {
      return this.httpLink.request(operation)
    }
  }
}

const link = new SocketSubscriptionSplitLink(httpLink)

export function setApolloSocketToken(token: string) {
  const phoenixSocket = new PhoenixSocket(socketOrigin + '/socket', {
    params: {token}
  })
  const absintheSocket = AbsintheSocket.create(phoenixSocket)
  link.absintheSocketLink = createAbsintheSocketLink(
    absintheSocket
  ) as unknown as ApolloLink
}

const cache = new InMemoryCache()

export const client = new ApolloClient({
  link,
  cache
})

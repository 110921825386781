import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {Button} from './ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import {useState} from 'react'
import {cn} from '@/lib/utils'

export function ThreeDot({children}: {children: React.ReactNode}) {
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          size="icon"
          variant="ghost"
          className={open ? 'bg-active hover:bg-active' : ''}
        >
          <EllipsisVerticalIcon className="w-6 h-6" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" sideOffset={0} className="w-48">
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

interface ThreeDotItemProps {
  text: string
  Icon?: React.ElementType
  onClick?: () => void
  destructive?: boolean
}

export function ThreeDotItem({
  text,
  Icon,
  onClick,
  destructive
}: ThreeDotItemProps) {
  return (
    <DropdownMenuItem
      onClick={onClick}
      className={cn(destructive && 'text-red-500')}
    >
      {Icon && <Icon className="mr-2 h-4 w-4" />}
      <span>{text}</span>
    </DropdownMenuItem>
  )
}

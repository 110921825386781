import {useMemo, useState} from 'react'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useMutation} from '@apollo/client'
import {Header} from '@/components/Header'
import {SignInWithPasswordDocument} from '@/gql/gen/graphql'
import {useAuth} from '@/hooks/useAuth'
import {getLastUsedUsername} from '@/utils/last-used-username'
import {Form, FormContent, FormItem} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {SimplePageContainer} from '@/components/SimplePageContainer'
import {isIOSSafari, isScoopIOS} from '@/util/ios'
import {Separator} from '@/components/ui/separator'

const formSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
  rememberMe: z.boolean()
})

export default function SignIn() {
  const auth = useAuth()
  const [signIn, {error}] = useMutation(SignInWithPasswordDocument)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const defaultUsername = useMemo(() => getLastUsedUsername(), [])
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: defaultUsername,
      password: '',
      rememberMe: false
    }
  })

  const [loading, setLoading] = useState(false)
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true)
    try {
      await signIn({
        variables: {input: values}
      })
      await auth.signedIn()
      const redirectTo = searchParams.get('redirect_to')
      navigate(redirectTo || '/')
    } catch (err) {
      console.error('Sign in failed:', err)
      setLoading(false)
    }
  }

  return (
    <SimplePageContainer>
      <Header center>Sign in</Header>
      <Form form={form} onSubmit={onSubmit} error={error}>
        <FormContent>
          <FormItem
            form={form}
            name="username"
            label="Username"
            autoFocus={defaultUsername === ''}
          />
          <FormItem
            form={form}
            name="password"
            label="Password"
            type="password"
            autoFocus={defaultUsername !== ''}
          />
          <FormItem
            form={form}
            name="rememberMe"
            type="checkbox"
            checkboxText="Remember me for a week"
          />
          <Button type="submit" disabled={loading} className="w-full">
            Sign in
          </Button>
        </FormContent>
      </Form>

      {isIOSSafari() && !isScoopIOS() && (
        <>
          <Separator className="my-6" />
          <div className="flex justify-center">
            <Button variant="secondary" className="w-full" asChild>
              <a href="itms-services://?action=download-manifest&url=https://sc.oop.so/downloads/scoop-ios.plist">
                Install Scoop for iOS
              </a>
            </Button>
          </div>
        </>
      )}
    </SimplePageContainer>
  )
}

import {formatError} from '@/utils/formatting'
import {useMemo} from 'react'

interface Props {
  error?: any
}

export function ErrorMessage({error}: Props) {
  const message = useMemo(() => error && formatError(error), [error])
  if (!message) {
    return null
  }
  return <div className="text-red-500">{message}</div>
}

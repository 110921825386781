import {Header} from '@/components/Header'
import {LoadingSpinner} from '@/components/LoadingSpinner'
import {SubmenuPageContainer} from '@/components/SubmenuPageContainer'
import {Button} from '@/components/ui/button'
import {Switch} from '@/components/ui/switch'
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card'
import {useToast} from '@/hooks/use-toast'
import {
  isScoopIOS,
  onMessageFromScoopIOS,
  sendMessageToScoopIOS
} from '@/util/ios'
import {useEffect, useState} from 'react'
import {Label} from '@/components/ui/label'
import {useApolloClient} from '@apollo/client'
import {
  CreateUserDeviceDocument,
  DeleteUserDeviceDocument,
  GetUserDeviceDocument,
  UserDevice
} from '@/gql/gen/graphql'
import {useMounted} from '@/hooks/useMounted'

export default function MySettings() {
  const {toast} = useToast()

  useEffect(() => {
    return onMessageFromScoopIOS(envelope => {
      switch (envelope.message.kind) {
        case 'ShowToast':
          toast({
            title: 'Message from iOS',
            description: envelope.message.message
          })
          break
      }
    })
  }, [toast])

  return (
    <SubmenuPageContainer>
      <Header backTo="/settings">My Settings</Header>

      <div className="flex flex-col gap-y-6">
        <Card>
          <CardHeader>
            <CardTitle>iOS alert test</CardTitle>
          </CardHeader>
          <CardContent>
            <Button
              onClick={() => {
                sendMessageToScoopIOS({
                  kind: 'ShowAlert',
                  message: "Say something iOS'y please"
                })
              }}
            >
              Trigger iOS alert
            </Button>
          </CardContent>
        </Card>

        {isScoopIOS() && <IOSPushNotifications />}

        {isScoopIOS() && (
          <Button onClick={() => window.location.reload()} variant="ghost">
            Reload
          </Button>
        )}
      </div>
    </SubmenuPageContainer>
  )
}

function IOSPushNotifications() {
  const apolloClient = useApolloClient()
  const mounted = useMounted()
  const {toast} = useToast()

  // Keep track of the current notification status and info about the device
  const [status, setStatus] = useState<
    'enabled' | 'disabled' | 'denied' | null
  >(null)
  const [device, setDevice] = useState<UserDevice | null>(null)
  const [working, setWorking] = useState(false)

  // When the component mounts, get the push notification status from iOS
  useEffect(() => {
    async function load() {
      // Get push notification status from iOS
      const reply = await sendMessageToScoopIOS({
        kind: 'GetPushNotificationStatus'
      })
      console.log('GetPushNotificationStatus reply', reply)
      if (!mounted.current) {
        return
      }
      // If denied, then stop here
      if (reply.status === 'denied') {
        setStatus('denied')
        return
      }
      // If not enabled or token is missing, then stop here
      if (reply.status !== 'enabled' || !reply.token) {
        setStatus('disabled')
        return
      }
      // Ensure that device token is registered in the backend
      const deviceRes = await apolloClient.query({
        query: GetUserDeviceDocument,
        variables: {
          deviceToken: reply.token
        }
      })
      if (!mounted.current) {
        return
      }
      const device = deviceRes.data.userDevice
      // If token is not found in the backend, it must be because notifications
      // have been disabled
      if (device) {
        setStatus('enabled')
        setDevice(device)
      } else {
        setStatus('disabled')
      }
    }
    load()
  }, [toast, apolloClient, mounted])

  async function enablePushNotifications() {
    console.log('enablePushNotifications')
    setWorking(true)
    try {
      // Enable push notifications on iOS
      const reply = await sendMessageToScoopIOS({
        kind: 'EnablePushNotifications'
      })
      if (!mounted.current) {
        return
      }
      if (reply.error) {
        toast({
          title: 'Failed to enable push notifications',
          description: reply.error,
          variant: 'destructive'
        })
        return
      }
      if (reply.denied) {
        setStatus('denied')
        return
      }
      // Register device token in the backend
      const deviceRes = await apolloClient.mutate({
        mutation: CreateUserDeviceDocument,
        variables: {
          input: {
            name: reply.name!,
            deviceToken: reply.token!
          }
        }
      })
      setStatus('enabled')
      setDevice(deviceRes.data!.createUserDevice!.result!)
    } finally {
      setWorking(false)
    }
  }

  async function disablePushNotifications() {
    console.log('disablePushNotifications')
    if (!device) {
      return
    }
    setWorking(true)
    try {
      // Note that we do not need to disable push notifications on iOS, we just
      // need to remove the device token from the backend
      await apolloClient.mutate({
        mutation: DeleteUserDeviceDocument,
        variables: {
          id: device!.id
        }
      })
      setStatus('disabled')
      setDevice(null)
    } finally {
      setWorking(false)
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>iOS push notifications</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-y-4">
        <div>
          Push notifications are sent e.g. when there's a call from a gate.
        </div>
        {status == null ? (
          <LoadingSpinner />
        ) : status === 'denied' ? (
          <div>
            Please go to Settings → Notifications → Scoop to allow
            notifications.
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Switch
              id="airplane-mode"
              checked={status === 'enabled'}
              onCheckedChange={checked =>
                checked ? enablePushNotifications() : disablePushNotifications()
              }
              disabled={working}
            />
            <Label htmlFor="airplane-mode">Receive push notifications</Label>
            {working && <LoadingSpinner />}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

import {
  Navigate,
  NavLink,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom'
import logomark from '@/assets/images/logomark.svg'
import {
  CogIcon,
  HomeIcon,
  ShoppingCartIcon
} from '@heroicons/react/24/solid'
import {useAuth} from '@/hooks/useAuth'
import {shouldUseViewTransition} from '@/utils/view-transition'
import {LoadingSpinner} from '@/components/LoadingSpinner'
import {useEffect} from 'react'

interface MenuItem {
  icon: typeof HomeIcon
  label: string
  to: string
}

const menuItems: MenuItem[] = [
  {icon: HomeIcon, label: 'Home', to: '/home'},
  {
    icon: ShoppingCartIcon,
    label: 'Shopping',
    to: '/shopping-list'
  },
  {icon: CogIcon, label: 'Settings', to: '/settings'}
]

export default function Authenticated() {
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  // Remember last-used location
  useEffect(() => {
    if (location.pathname === '/') {
      const lastUsedLocation = localStorage.getItem('lastUsedLocation')
      if (lastUsedLocation) {
        navigate(lastUsedLocation)
      } else {
        navigate('/home')
      }
    } else {
      localStorage.setItem('lastUsedLocation', location.pathname)
    }
  }, [location.pathname, navigate])

  if (!auth.viewerLoaded) {
    return (
      <div className="p-6">
        <LoadingSpinner />
      </div>
    )
  }

  if (!auth.viewer) {
    const redirectTo = location.pathname + location.search
    return (
      <Navigate
        to={
          '/sign-in' +
          (redirectTo === '/'
            ? ''
            : '?redirect_to=' + encodeURIComponent(redirectTo))
        }
      />
    )
  }

  return (
    <div className="flex h-[calc(100dvh)] flex-col-reverse items-stretch lg:flex-row">
      <div className="flex overflow-x-auto overflow-y-hidden border-t border-gray-20 p-1 gap-y-1 lg:flex-col lg:border-r lg:border-t-0 lg:overflow-x-hidden lg:overflow-y-auto">
        <div className="hidden w-16 h-14 justify-center items-center lg:flex">
          <img src={logomark} className="block size-8" alt="Scoop" />
        </div>
        <nav className="flex flex-1">
          <ul
            role="list"
            className="flex flex-1 justify-center min-w-0 min-h-0 gap-x-1 lg:flex-col lg:gap-y-1 lg:gap-x-0 lg:justify-start"
          >
            {menuItems.map((item, idx) => (
              <li key={idx}>
                <NavLink
                  to={item.to}
                  className={({isActive}) =>
                    'flex flex-col justify-center size-16 px-1 rounded-md ' +
                    (isActive
                      ? 'text-primary bg-primary-100'
                      : 'text-slate-600 hover:bg-primary-50')
                  }
                  unstable_viewTransition={shouldUseViewTransition(
                    location.pathname,
                    item.to
                  )}
                >
                  <item.icon className="size-8 self-center" />
                  <div className="text-xs text-center truncate">
                    {item.label}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <main id="main" className="flex-1 min-w-0 min-h-0 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  )
}

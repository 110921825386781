import {ArrowPathIcon} from '@heroicons/react/24/solid'
import {useEffect, useState} from 'react'

type Props = {
  text?: string
}

export function LoadingSpinner({text}: Props) {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true)
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  let output = <ArrowPathIcon className="w-6 h-6 text-blue-500 animate-spin" />
  if (text) {
    output = (
      <div className="flex items-center gap-x-2">
        {output}
        <span>{text}</span>
      </div>
    )
  }
  return (output = (
    <div
      className={
        'transition-opacity duration-300 ' +
        (visible ? 'opacity-100' : 'opacity-0')
      }
    >
      {output}
    </div>
  ))
}

import {Dialog, DialogContent, DialogHeader} from './ui/dialog'
import {UpdateUserDocument, UserFragment, UserRole} from '@/gql/gen/graphql'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation} from '@apollo/client'
import {Form, FormContent, FormItem} from './ui/form'
import {MultiCheckbox} from './MultiCheckbox'
import {allUserRoles, formatUserRole} from '@/utils/enums'
import {DialogButtons} from './DialogButtons'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  user: UserFragment
}

export function EditUserDialog({open, onOpenChange, user}: Props) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Content user={user} onClose={() => onOpenChange(false)} />
      </DialogContent>
    </Dialog>
  )
}

const formSchema = z.object({
  roles: z.set(z.nativeEnum(UserRole))
})

interface ContentProps {
  user: UserFragment
  onClose: () => void
}

function Content({user, onClose}: ContentProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      roles: new Set(user.roles)
    }
  })

  const [updateUser, {loading, error}] = useMutation(UpdateUserDocument)
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateUser({
      variables: {
        id: user.id,
        input: {
          roles: Array.from(values.roles)
        }
      }
    })
    onClose()
  }

  return (
    <Form form={form} onSubmit={onSubmit} error={error}>
      <DialogHeader title={`Edit user: ${user.username}`} />
      <FormContent>
        <FormItem
          form={form}
          name="roles"
          label="Roles"
          render={({field}) => (
            <MultiCheckbox
              options={allUserRoles}
              format={formatUserRole}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormContent>
      <DialogButtons
        submitText="Save user"
        disabled={loading}
        onClose={onClose}
      />
    </Form>
  )
}

import {Header} from '@/components/Header'
import {SubmenuPageContainer} from '@/components/SubmenuPageContainer'
import {Button} from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableThreeDotCell
} from '@/components/ui/table'
import {
  DeleteUserDocument,
  ListUsersDocument,
  UserFragment
} from '@/gql/gen/graphql'
import {useMutation, useQuery} from '@apollo/client'
import {KeyIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/solid'
import React, {useState} from 'react'
import {CreateUserDialog} from '@/components/CreateUserDialog'
import {EditUserDialog} from '@/components/EditUserDialog'
import {ThreeDot, ThreeDotItem} from '@/components/ThreeDot'
import {ConfirmDialog} from '@/components/ConfirmDialog'
import {useToast} from '@/hooks/use-toast'
import {formatError} from '@/utils/formatting'
import {formatUserRole} from '@/utils/enums'
import {Badge} from '@/components/ui/badge'
import {ResetUserPasswordDialog} from '@/components/ResetUserPasswordDialog'

export default function Users() {
  const res = useQuery(ListUsersDocument)
  function optimisticRemove(id: string) {
    res.updateQuery(prev => ({
      ...prev,
      users: prev.users?.filter(u => u.id !== id)
    }))
  }

  // Create
  const [createOpen, setCreateOpen] = useState(false)

  return (
    <SubmenuPageContainer>
      <Header
        backTo="/settings"
        actions={<Button onClick={() => setCreateOpen(true)}>New user</Button>}
      >
        Users
      </Header>

      <CreateUserDialog
        open={createOpen}
        onOpenChange={setCreateOpen}
        onSave={() => {
          res.refetch()
        }}
      />

      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHead>Username</TableHead>
            <TableHead>Roles</TableHead>
            <TableHead />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {res.data?.users.map(user => (
            <UserRow
              key={user.id}
              user={user}
              optimisticRemove={optimisticRemove}
              refetch={res.refetch}
            />
          ))}
        </TableBody>
      </Table>
    </SubmenuPageContainer>
  )
}

interface UserRowProps {
  user: UserFragment
  optimisticRemove: (id: string) => void
  refetch: () => void
}

function UserRow({user, optimisticRemove, refetch}: UserRowProps) {
  const {toast} = useToast()

  // Edit
  const [editOpen, setEditOpen] = useState(false)

  // Reset password
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false)

  // Delete
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteUser] = useMutation(DeleteUserDocument)
  async function confirmDeleteUser() {
    // Optimistically remove the user
    setDeleteOpen(false)
    optimisticRemove(user.id)
    try {
      await deleteUser({variables: {id: user.id}})
    } catch (e) {
      // Undo optimistic update
      refetch()
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  return (
    <TableRow>
      <EditUserDialog open={editOpen} onOpenChange={setEditOpen} user={user} />

      <ResetUserPasswordDialog
        open={resetPasswordOpen}
        onOpenChange={setResetPasswordOpen}
        user={user}
      />

      <ConfirmDialog
        open={deleteOpen}
        onOpenChange={setDeleteOpen}
        onConfirm={confirmDeleteUser}
        onCancel={() => console.log('cancel')}
        destructive
      >
        <p>
          Confirm deleting <b>{user.username}</b>?
        </p>
      </ConfirmDialog>

      <TableCell>{user.username}</TableCell>
      <TableCell>
        {user.roles.map(role => (
          <React.Fragment key={role}>
            <Badge>{formatUserRole(role)}</Badge>{' '}
          </React.Fragment>
        ))}
      </TableCell>
      <TableThreeDotCell>
        <ThreeDot>
          <ThreeDotItem
            text="Edit user"
            Icon={PencilIcon}
            onClick={() => setEditOpen(true)}
          />
          <ThreeDotItem
            text="Reset password"
            Icon={KeyIcon}
            onClick={() => setResetPasswordOpen(true)}
          />
          <ThreeDotItem
            text="Delete user"
            Icon={TrashIcon}
            onClick={() => setDeleteOpen(true)}
            destructive
          />
        </ThreeDot>
      </TableThreeDotCell>
    </TableRow>
  )
}

import {UserRole} from '@/gql/gen/graphql'

export const allUserRoles = [
  UserRole.FundManager,
  UserRole.FundViewer,
  UserRole.Shopper,
  UserRole.UserManager
]

export function formatUserRole(role: UserRole) {
  const formatted = role.toLowerCase().replaceAll('_', ' ')
  return formatted.charAt(0).toUpperCase() + formatted.slice(1)
}

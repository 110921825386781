import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import {cn} from '@/lib/utils'
import {CheckIcon} from '@heroicons/react/24/solid'

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    text?: string
  }
>(({text, className, ...props}, ref) => {
  let checkbox = (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'peer h-5 w-5 shrink-0 rounded-sm border border-slate-300 ring-offset-white hover:bg-hover active:bg-active',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-1',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500 data-[state=checked]:text-white hover:data-[state=checked]:bg-blue-400 hover:data-[state=checked]:border-blue-400 active:data-[state=checked]:bg-blue-600 active:data-[state=checked]:border-blue-600',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        <CheckIcon className="h-5 w-5" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )

  if (text) {
    checkbox = (
      <label className="cursor-pointer flex items-center gap-2 hover:text-blue-500">
        {checkbox}
        <div>{text}</div>
      </label>
    )
  }

  return checkbox
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export {Checkbox}

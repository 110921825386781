import {Header} from '@/components/Header'
import {PageContainer} from '@/components/PageContainer'
import {useParams} from 'react-router-dom'

export default function Gate() {
  const {gateId} = useParams()
  return (
    <PageContainer>
      <Header>Gate: {gateId}</Header>
      <div>Gate stuff coming soon-ish!</div>
    </PageContainer>
  )
}

import {createContext} from 'react'
import {ViewerUserFragment} from '@/gql/gen/graphql'

export interface AuthContextValue {
  viewerLoaded: boolean
  viewer: ViewerUserFragment | null
  signedIn: () => Promise<void>
}

export const AuthContext = createContext<AuthContextValue>(null!)

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from './ui/dialog'
import {Button} from './ui/button'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Form, FormContent, FormItem} from './ui/form'
import {CreateUserDocument, UserRole} from '@/gql/gen/graphql'
import {formatUserRole, allUserRoles} from '@/utils/enums'
import {useMutation} from '@apollo/client'
import {MultiCheckbox} from './MultiCheckbox'
import {DialogButtons} from './DialogButtons'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onSave: () => void
}

export function CreateUserDialog({open, onOpenChange, onSave}: Props) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Content onClose={() => onOpenChange(false)} onSave={onSave} />
      </DialogContent>
    </Dialog>
  )
}

const formSchema = z.object({
  username: z.string().min(2).max(20),
  roles: z.set(z.nativeEnum(UserRole))
})

interface ContentProps {
  onClose: () => void
  onSave: () => void
}

export function Content({onClose, onSave}: ContentProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      roles: new Set()
    }
  })

  const [createUser, {data, loading, error}] = useMutation(CreateUserDocument)
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await createUser({
      variables: {
        input: {
          username: values.username,
          roles: Array.from(values.roles),
          generatePassword: true
        }
      }
    })
    onSave()
  }

  if (data?.createUser) {
    const user = data.createUser.result
    return (
      <>
        <DialogHeader title="User created" />
        <p>
          New user, <b>{user?.username}</b>, was created.
        </p>
        <p>
          Password (only shown once):
          <br />
          {data.createUser.metadata?.cleartextPassword}
        </p>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </>
    )
  }

  return (
    <>
      <Form form={form} onSubmit={onSubmit} error={error}>
        <DialogHeader title="New user" />
        <FormContent>
          <FormItem
            form={form}
            name="username"
            label="Username"
            placeholder="Enter username"
          />
          <FormItem
            form={form}
            name="roles"
            label="Roles"
            render={({field}) => (
              <MultiCheckbox
                options={allUserRoles}
                format={formatUserRole}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </FormContent>
        <DialogButtons
          submitText="Create user"
          disabled={loading}
          onClose={onClose}
        />
      </Form>
    </>
  )
}

import {useEffect, useRef} from 'react'
import {useApolloClient} from '@apollo/client'
import {SignOutDocument} from '@/gql/gen/graphql'

export default function SignOut() {
  const client = useApolloClient()
  const signedOut = useRef(false)

  useEffect(() => {
    if (signedOut.current) {
      return
    }
    signedOut.current = true
    async function signOut() {
      await client.mutate({mutation: SignOutDocument})
      window.location.href = '/sign-in'
    }
    signOut()
  }, [client])

  return <div>Signing out...</div>
}

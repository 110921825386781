import {useEffect, useId, useRef} from 'react'
import {Slot} from '@radix-ui/react-slot'
import {
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  UseFormReturn
} from 'react-hook-form'

import {cn} from '@/lib/utils'
import {Label} from '@/components/ui/label'
import {Input} from './input'
import {Checkbox} from './checkbox'
import {ErrorMessage} from '../ErrorMessage'

interface FormProps<TFieldValues extends FieldValues, TContext> {
  children: React.ReactNode
  form: UseFormReturn<TFieldValues, TContext, undefined>
  onSubmit: SubmitHandler<TFieldValues>
  error?: any
}

export function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>({children, form, onSubmit, error}: FormProps<TFieldValues, TContext>) {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="contents">
        {children}
        <ErrorMessage error={error} />
      </form>
    </FormProvider>
  )
}

export function FormContent({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) {
  return <div className={cn('space-y-6', className)}>{children}</div>
}

interface FormItemProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  form: UseFormReturn<TFieldValues, any, undefined>
  name: TName
  label?: string
  type?: 'text' | 'password' | 'checkbox'
  placeholder?: string
  checkboxText?: string
  autoFocus?: boolean
  autoCapitalize?: string
  render?: (props: {
    field: ControllerRenderProps<TFieldValues, TName>
  }) => React.ReactNode
}

export function FormItem<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  form,
  name,
  label,
  type,
  checkboxText,
  placeholder,
  render,
  autoFocus,
  autoCapitalize
}: FormItemProps<TFieldValues, TName>) {
  const id = useId()
  const {getFieldState, formState} = useFormContext()

  const formItemId = `${id}-form-item`
  const formDescriptionId = `${id}-form-item-description`
  const formMessageId = `${id}-form-item-message`

  const fieldState = getFieldState(name, formState)
  const {error} = fieldState

  const errorMessage = error ? String(error.message) : null

  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (autoFocus) {
      wrapperRef.current?.querySelector('input')?.focus({preventScroll: true})
    }
  }, [autoFocus])

  return (
    <Controller
      control={form.control}
      name={name}
      render={({field}) => (
        <div ref={wrapperRef} className="space-y-1">
          {label && (
            <Label
              className={cn(error && 'text-red-500 dark:text-red-900')}
              htmlFor={formItemId}
            >
              {label}
            </Label>
          )}
          <Slot
            id={formItemId}
            aria-describedby={
              !error
                ? `${formDescriptionId}`
                : `${formDescriptionId} ${formMessageId}`
            }
            aria-invalid={!!error}
          >
            {render ? (
              render({field})
            ) : type === 'checkbox' ? (
              <div>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  text={checkboxText}
                />
              </div>
            ) : (
              <Input
                type={type}
                placeholder={placeholder}
                autoCapitalize={autoCapitalize}
                {...field}
              />
            )}
          </Slot>

          {errorMessage && (
            <p
              id={formMessageId}
              className={cn(
                'text-sm font-medium text-red-500 dark:text-red-900'
              )}
            >
              {errorMessage}
            </p>
          )}
        </div>
      )}
    />
  )
}

// type FormFieldContextValue<
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
// > = {
//   name: TName
// }

// const FormFieldContext = React.createContext<FormFieldContextValue>(
//   {} as FormFieldContextValue
// )

// const FormField = <
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
// >({
//   ...props
// }: ControllerProps<TFieldValues, TName>) => {
//   return (
//     <FormFieldContext.Provider value={{name: props.name}}>
//       <Controller {...props} />
//     </FormFieldContext.Provider>
//   )
// }

// const useFormField = () => {
//   const fieldContext = React.useContext(FormFieldContext)
//   const itemContext = React.useContext(FormItemContext)
//   const {getFieldState, formState} = useFormContext()

//   const fieldState = getFieldState(fieldContext.name, formState)

//   if (!fieldContext) {
//     throw new Error('useFormField should be used within <FormField>')
//   }

//   const {id} = itemContext

//   return {
//     id,
//     name: fieldContext.name,
//     formItemId: `${id}-form-item`,
//     formDescriptionId: `${id}-form-item-description`,
//     formMessageId: `${id}-form-item-message`,
//     ...fieldState
//   }
// }

// type FormItemContextValue = {
//   id: string
// }

// const FormItemContext = React.createContext<FormItemContextValue>(
//   {} as FormItemContextValue
// )

// const FormItem = React.forwardRef<
//   HTMLDivElement,
//   React.HTMLAttributes<HTMLDivElement>
// >(({className, ...props}, ref) => {
//   const id = React.useId()

//   return (
//     <FormItemContext.Provider value={{id}}>
//       <div ref={ref} className={cn('space-y-2', className)} {...props} />
//     </FormItemContext.Provider>
//   )
// })
// FormItem.displayName = 'FormItem'

// const FormLabel = React.forwardRef<
//   React.ElementRef<typeof LabelPrimitive.Root>,
//   React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
// >(({className, ...props}, ref) => {
//   const {error, formItemId} = useFormField()

//   return (
//     <Label
//       ref={ref}
//       className={cn(error && 'text-red-500 dark:text-red-900', className)}
//       htmlFor={formItemId}
//       {...props}
//     />
//   )
// })
// FormLabel.displayName = 'FormLabel'

// const FormControl = React.forwardRef<
//   React.ElementRef<typeof Slot>,
//   React.ComponentPropsWithoutRef<typeof Slot>
// >(({...props}, ref) => {
//   const {error, formItemId, formDescriptionId, formMessageId} = useFormField()

//   return (
//     <Slot
//       ref={ref}
//       id={formItemId}
//       aria-describedby={
//         !error
//           ? `${formDescriptionId}`
//           : `${formDescriptionId} ${formMessageId}`
//       }
//       aria-invalid={!!error}
//       {...props}
//     />
//   )
// })
// FormControl.displayName = 'FormControl'

// const FormDescription = React.forwardRef<
//   HTMLParagraphElement,
//   React.HTMLAttributes<HTMLParagraphElement>
// >(({className, ...props}, ref) => {
//   const {formDescriptionId} = useFormField()

//   return (
//     <p
//       ref={ref}
//       id={formDescriptionId}
//       className={cn('text-sm text-slate-500 dark:text-slate-400', className)}
//       {...props}
//     />
//   )
// })
// FormDescription.displayName = 'FormDescription'

// const FormMessage = React.forwardRef<
//   HTMLParagraphElement,
//   React.HTMLAttributes<HTMLParagraphElement>
// >(({className, children, ...props}, ref) => {
//   const {error, formMessageId} = useFormField()
//   const body = error ? String(error?.message) : children

//   if (!body) {
//     return null
//   }

//   return (
//     <p
//       ref={ref}
//       id={formMessageId}
//       className={cn(
//         'text-sm font-medium text-red-500 dark:text-red-900',
//         className
//       )}
//       {...props}
//     >
//       {body}
//     </p>
//   )
// })
// FormMessage.displayName = 'FormMessage'

// const FormInput = createFormItem(({field}) => <Input {...field} />)
// const FormCheckbox = createFormItem(({field}) => <Checkbox checked={field.value} onCheckedChange={field.onChange} />)

// interface FormInputProps<
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
// > extends Omit<FormItemProps<TFieldValues, TName>, 'render'> {
//   test?: string
// }

// function FormInput<
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
// >({test, ...props}: FormInputProps<TFieldValues, TName>) {
//   console.log('test', test)
//   return <FormItem render={({field}) => <Input {...field} />} {...props} />
// }

// interface FormInputProps<
//   TFieldValues extends FieldValues,
//   TContext,
//   TTransformedValues extends FieldValues | undefined
// > {
//   form: UseFormReturn<TFieldValues, TContext, TTransformedValues>
//   name: FieldPath<TFieldValues>
//   label?: string
// }

// function FormInput<
//   TFieldValues extends FieldValues = FieldValues,
//   TContext = any,
//   TTransformedValues extends FieldValues | undefined = undefined
// >({
//   form,
//   name,
//   label
// }: FormInputProps<TFieldValues, TContext, TTransformedValues>) {
//   return (
//     <FormField
//       control={form.control}
//       name={name}
//       render={({field}) => (
//         <FormItem>
//           {label && <FormLabel>{label}</FormLabel>}
//           <FormControl>
//             <Input {...field} />
//           </FormControl>
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   )
// }

// export {Form, FormInput}

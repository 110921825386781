import {useCallback, useLayoutEffect} from 'react'
import {useQuery} from '@apollo/client'
import {GetViewerDocument} from '@/gql/gen/graphql'
import {AuthContext, AuthContextValue} from '@/contexts/AuthContext'
import {setLastUsedUsername} from '@/utils/last-used-username'
import {setApolloSocketToken} from '@/utils/apollo-client'

export function AuthProvider({children}: {children: React.ReactNode}) {
  const {loading, error, data, refetch} = useQuery(GetViewerDocument)
  const viewer = data?.viewer ?? null

  const socketToken = viewer?.socketToken
  useLayoutEffect(() => {
    if (socketToken) {
      setApolloSocketToken(socketToken)
    }
  }, [socketToken])

  const signedIn = useCallback(async () => {
    const res = await refetch()
    const newViewer = res.data.viewer
    if (newViewer) {
      setLastUsedUsername(newViewer.username)
    }
  }, [refetch])

  const auth: AuthContextValue = {
    viewerLoaded: !loading,
    viewer,
    signedIn
  }

  if (error) {
    // TODO: Nicer error
    return <div>AuthProvider error: {error.message}</div>
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

import {Dialog, DialogContent, DialogFooter, DialogHeader} from './ui/dialog'
import {ResetUserPasswordDocument, UserFragment} from '@/gql/gen/graphql'
import {useMutation} from '@apollo/client'
import {DialogButtons} from './DialogButtons'
import {ErrorMessage} from './ErrorMessage'
import {Button} from './ui/button'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  user: UserFragment
}

export function ResetUserPasswordDialog({open, onOpenChange, user}: Props) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Content user={user} onClose={() => onOpenChange(false)} />
      </DialogContent>
    </Dialog>
  )
}

interface ContentProps {
  user: UserFragment
  onClose: () => void
}

function Content({user, onClose}: ContentProps) {
  const [resetUserPassword, {data, loading, error}] = useMutation(
    ResetUserPasswordDocument
  )
  const onSubmit = async () => {
    await resetUserPassword({
      variables: {
        id: user.id
      }
    })
  }

  if (data?.resetUserPassword) {
    return (
      <>
        <DialogHeader title="Reset password" />
        <p>
          The password for <b>{user?.username}</b> was reset.
        </p>
        <p>
          New Password (only shown once):
          <br />
          {data.resetUserPassword.metadata?.cleartextPassword}
        </p>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </>
    )
  }

  return (
    <>
      <DialogHeader title={`Reset password`} />
      <p>
        Are you sure you want to reset the password for <b>{user.username}</b>?
      </p>
      <DialogButtons
        submitText="Reset password"
        onSubmit={onSubmit}
        disabled={loading}
        onClose={onClose}
      />
      <ErrorMessage error={error} />
    </>
  )
}

import {ViewerUserFragment} from '@/gql/gen/graphql'
import {useAuth} from './useAuth'

export function useViewer(): ViewerUserFragment {
  const {viewer} = useAuth()
  if (!viewer) {
    throw new Error('useViewer must be used only when a user is signed in')
  }
  return viewer
}

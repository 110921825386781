import {Checkbox} from './ui/checkbox'

interface Props<TValue extends string> {
  options: TValue[]
  format: (value: TValue) => string
  value: Set<TValue>
  onChange: (value: Set<TValue>) => void
}

export function MultiCheckbox<TValue extends string>({
  options,
  format,
  value,
  onChange
}: Props<TValue>) {
  return (
    <div>
      {options.map(option => (
        <Checkbox
          key={option}
          checked={value.has(option)}
          onCheckedChange={checked => {
            const newValue = new Set(value)
            if (checked) {
              newValue.add(option)
              onChange(newValue)
            } else {
              newValue.delete(option)
              onChange(newValue)
            }
          }}
          text={format(option)}
        />
      ))}
    </div>
  )
}

import logo from '@/assets/images/logo.svg'

interface Props {
  children: React.ReactNode
}

export const SimplePageContainer = ({children}: Props) => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 mx-auto max-w-md">
      <div className="flex justify-center mb-6">
        <img src={logo} style={{width: '92px', height: '32px'}} alt="Scoop" />
      </div>
      {children}
    </div>
  )
}
